var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-box" }, [
    _c("div", { staticClass: "login-content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "input-box" },
        [
          _c("el-input", {
            staticClass: "username",
            attrs: {
              placeholder: "请输入手机号/邮箱",
              "prefix-icon": "el-icon-user",
            },
            on: {
              focus: function ($event) {
                _vm.tips = ""
              },
            },
            model: {
              value: _vm.userName,
              callback: function ($$v) {
                _vm.userName = $$v
              },
              expression: "userName",
            },
          }),
          _c("el-input", {
            attrs: {
              placeholder: "请输入密码",
              "prefix-icon": "el-icon-lock",
              type: "password",
            },
            on: {
              focus: function ($event) {
                _vm.tips = ""
              },
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
          _c("div", { staticClass: "foget theme-color" }, [
            _vm.tips
              ? _c(
                  "div",
                  {
                    staticClass: "error-tips",
                    staticStyle: {
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-error" }),
                    _vm._v(_vm._s(_vm.tips)),
                  ]
                )
              : _vm._e(),
            _c("span", { on: { click: _vm.forgetPwd } }, [
              _vm._v("忘记密码？"),
            ]),
          ]),
          _c("div", { staticClass: "flex-box btn-box" }, [
            _c("div", { staticClass: "wid-btn", on: { click: _vm.login } }, [
              _vm._v("登录"),
            ]),
            _c("div", { staticClass: "wi-btn", on: { click: _vm.handleReg } }, [
              _vm._v("注册"),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("@/assets/img/mu-logo.png") } }),
      _c("div", { staticClass: "sub-name" }, [
        _vm._v("如您已有账户，请直接登录"),
        _c("br"),
        _vm._v("如还没有账号，请先注册"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }