<template>
 <div class="login-box">
  <div class="login-content">
  <div class="logo">
    <img src="@/assets/img/mu-logo.png"/>
    <div class="sub-name">如您已有账户，请直接登录<br/>如还没有账号，请先注册</div>
  </div>
  <div  class="input-box">
    <el-input
      class="username"
      @focus="tips=''"
      placeholder="请输入手机号/邮箱"
      prefix-icon="el-icon-user"
      v-model="userName">
    </el-input>
    <el-input
      @focus="tips=''"
      placeholder="请输入密码"
      prefix-icon="el-icon-lock"
      type="password"
      v-model="password">
    </el-input>
    <div class="foget theme-color">
      <div v-if="tips" style="position:absolute;top:0px;left:0px" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div>
      <span @click="forgetPwd">忘记密码？</span>
    </div>
    
    <div class="flex-box btn-box">
      <div class="wid-btn" @click="login">登录</div>
      <div class="wi-btn" @click="handleReg">注册</div>
    </div>
  </div>
 </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      tips:'',
      userName:'',
      password:''
    }
  },
  created(){
  },
  methods:{
    login(){
      if (this.userName === ''||this.password === '') {
        this.tips = "用户名或密码为空"
        return
      }
      let data = {
        type:'1',
        account:this.userName,
        password:this.password
      }
      this.$store.dispatch('auth/login', data).then(res => {
        if(res.token){
          this.$router.push({name:'home'})
        }
      })
    },
    forgetPwd(){
      this.$router.push({name:'pwd'})
    },
    handleReg(){
      this.$router.push({name:'register'})
    }
  }
}
</script>
<style lang="scss" scoped>
.login-box{
  padding:.8rem;
  .login-content{
    background: #fff;
    border-radius:8px;
    height: calc(100vh - 1.6rem);
    .flex-box{
      justify-content: space-between;
    }
  }

  .logo{
    text-align: center;
    padding-top:7rem;
    margin-bottom:2rem;
    img{
      width: 45%;
      margin-bottom:1rem;
    }
    .logo-name{
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }
  }
  .sub-name{
    text-align: center;
    color: #989898;
    font-size: .9rem;
  }
  .btn-box{
   div{
    width: 45%;
    text-align: center;
   }
    justify-content: space-between;
  }
}
.input-box{
  width: 90%;
  margin: 0px auto;
  .username{
    margin-bottom:1rem;
  }
  .el-input{
    width: 100%;
  }
  // button{
  //   width: 96%;
  // }
  .error-tips{
    font-size: .9rem;
    color: #f56c6c;
  }
}
.foget{
  height: 1.2rem;
  text-align: right;
  font-size: .9rem;
  margin:2% 0rem 1rem;
  // cursor: pointer;
  position: relative;
}

</style>
